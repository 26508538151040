import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  selectedLocationValue: null,
  isBaseLayersLoading: false,
  baseLayersGeojson: null,
  isDevicesBaseLayersLoading: false,
  isDevicesRouteDataLoading: false,
  isBeaconBasedColor: false,
  deviceBaseLayersGeojson: null,
  isSummary: true,
  summaryData: null,
  selectedDay: null,
  selectedServiceForDetails: null,
  coveredLayersGeojson: null,
  coveredLayersDateWiseGeojson: null,
  weekCalenderValue: dayjs(new Date()),
  selectedProperty: "mayport",
  weekSummaryFiles: {},
  selectedGISService: {},
  selectedGISServicePathOfMotion: {},
  selectedDevices: {},
  selectedGISServiceDate: {},
  selectedWeekSummary: null,
  selectedWeek: null,
  devicesColor: null,
  pathOfMotionData: null,
  pathOfMotionEntryPointsData: null,
  detailedData: null,
  beaconsListData: null,
  beaconTypeColors: null,
  isPathOfMotion: false,
  uploadedKmlData: null,
  devicesUniqueColors: null,
  devicesBeconCheckboxes: null,
  selectedDevicesDashboard: null,
  boundsForDevices: null,
  selectedDevicesGeoJson: null,
  selectedDevicesIdleGeoJson: null,
  selectedDevicesTransitGeoJson: null,
  selectedDevicesMissingGeoJson: null,
  devicesEquipmentData: null,
  boundingBoxForDevices: null,
  selectedDateForDevices: null,
  devicesDashboardLocationValue: null,
  devicesSummaryRef: null,
  devicesMapRef: null,
  isIdleLayers: false,
  isTransitLayers: false,
  isMissingLayers: false,
  allWeekDaysCombinedGeoJson: {
    type: "FeatureCollection",
    features: [],
  },
};

const GISDashboardSlice = createSlice({
  name: "gisDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedLocationValue: (state, action) => {
      return {
        ...state,
        selectedLocationValue: action.payload,
      };
    },
    setDevicesDashboardLocationValue: (state, action) => {
      return {
        ...state,
        devicesDashboardLocationValue: action.payload,
      };
    },
    setDevicesSummaryRef: (state, action) => {
      return {
        ...state,
        devicesSummaryRef: action.payload,
      };
    },
    setDevicesMapRef: (state, action) => {
      return {
        ...state,
        devicesMapRef: action.payload,
      };
    },
    setIsBaseLayersLoading: (state, action) => {
      return {
        ...state,
        isBaseLayersLoading: action.payload,
      };
    },
    setBaseLayersGeojson: (state, action) => {
      return {
        ...state,
        baseLayersGeojson: action.payload,
      };
    },
    setIsDevicesBaseLayersLoading: (state, action) => {
      return {
        ...state,
        isDevicesBaseLayersLoading: action.payload,
      };
    },
    setIsDevicesRouteDataLoading: (state, action) => {
      return {
        ...state,
        isDevicesRouteDataLoading: action.payload,
      };
    },
    setDeviceBaseLayersGeojson: (state, action) => {
      return {
        ...state,
        deviceBaseLayersGeojson: action.payload,
      };
    },
    setIsBeaconBasedColor: (state, action) => {
      return {
        ...state,
        isBeaconBasedColor: action.payload,
      };
    },
    setIsSummary: (state, action) => {
      return {
        ...state,
        isSummary: action.payload,
      };
    },
    setSummaryData: (state, action) => {
      return {
        ...state,
        summaryData: action.payload,
      };
    },
    setSelectedDay: (state, action) => {
      return {
        ...state,
        selectedDay: action.payload,
      };
    },
    setSelectedServiceForDetails: (state, action) => {
      return {
        ...state,
        selectedServiceForDetails: action.payload,
      };
    },
    setCoveredLayersGeojson: (state, action) => {
      return {
        ...state,
        coveredLayersGeojson: action.payload,
      };
    },
    setCoveredLayersDateWiseGeojson: (state, action) => {
      return {
        ...state,
        coveredLayersDateWiseGeojson: action.payload,
      };
    },
    setWeekCalenderValue: (state, action) => {
      return {
        ...state,
        weekCalenderValue: action.payload,
      };
    },
    setSelectedProperty: (state, action) => {
      return {
        ...state,
        selectedProperty: action.payload,
      };
    },
    setIsPathOfMotion: (state, action) => {
      return {
        ...state,
        isPathOfMotion: action.payload,
      };
    },
    setWeekSummaryFiles: (state, action) => {
      const { serviceName, results } = action.payload;
      state.weekSummaryFiles[serviceName] = results;
    },
    setSelectedGISService: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedGISService[serviceType] =
          !state.selectedGISService[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedGISService = action.payload;
      }
    },
    setSelectedGISServicePathOfMotion: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedGISServicePathOfMotion[serviceType] =
          !state.selectedGISServicePathOfMotion[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedGISServicePathOfMotion = action.payload;
      }
    },
    setSelectedDevices: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedDevices[serviceType] =
          !state.selectedDevices[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedDevices = action.payload;
      }
    },
    setSelectedGISServiceDate: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedGISServiceDate[serviceType] =
          !state.selectedGISServiceDate[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedGISServiceDate = action.payload;
      }
    },
    setSelectedWeek: (state, action) => {
      return {
        ...state,
        selectedWeek: action.payload,
      };
    },
    setDevicesColor: (state, action) => {
      return {
        ...state,
        devicesColor: action.payload,
      };
    },
    setPathOfMotionData: (state, action) => {
      return {
        ...state,
        pathOfMotionData: action.payload,
      };
    },
    setPathOfMotionEntryPointsData: (state, action) => {
      return {
        ...state,
        pathOfMotionEntryPointsData: action.payload,
      };
    },
    setSelectedWeekSummary: (state, action) => {
      return {
        ...state,
        selectedWeekSummary: action.payload,
      };
    },
    setDetailedData: (state, action) => {
      return {
        ...state,
        detailedData: action.payload,
      };
    },
    setUploadedKmlData: (state, action) => {
      return {
        ...state,
        uploadedKmlData: action.payload,
      };
    },
    setBeaconsListData: (state, action) => {
      return {
        ...state,
        beaconsListData: action.payload,
      };
    },
    setBeaconTypeColors: (state, action) => {
      return {
        ...state,
        beaconTypeColors: action.payload,
      };
    },
    setDevicesUniqueColors: (state, action) => {
      return {
        ...state,
        devicesUniqueColors: action.payload,
      };
    },
    setAllWeekDaysCombinedGeoJson: (state, action) => {
      state.allWeekDaysCombinedGeoJson.features.push(action.payload);
    },
    setDevicesBeconCheckboxes: (state, action) => {
      state.devicesBeconCheckboxes = action.payload;
    },
    updateAllDevicesCheckboxes: (state, action) => {
      const { deviceId, updatedObj } = action.payload;
      if (state.devicesBeconCheckboxes[deviceId]) {
        state.devicesBeconCheckboxes[deviceId] = updatedObj;
      }
    },
    updateDevicesBeconCheckboxes: (state, action) => {
      const { deviceId, beaconId } = action.payload;
      if (
        state.devicesBeconCheckboxes[deviceId] &&
        state.devicesBeconCheckboxes[deviceId][beaconId] !== undefined
      ) {
        state.devicesBeconCheckboxes[deviceId][beaconId] =
          !state.devicesBeconCheckboxes[deviceId][beaconId]; // Update the beacon value
      }
    },
    setSelectedDevicesDashboard: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedDevicesDashboard[serviceType] =
          !state.selectedDevicesDashboard[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedDevicesDashboard = action.payload;
      }
    },
    setSelectedDevicesGeoJson: (state, action) => {
      return {
        ...state,
        selectedDevicesGeoJson: action.payload,
      };
    },
    setSelectedDevicesIdleGeoJson: (state, action) => {
      return {
        ...state,
        selectedDevicesIdleGeoJson: action.payload,
      };
    },
    setSelectedDevicesTransitGeoJson: (state, action) => {
      return {
        ...state,
        selectedDevicesTransitGeoJson: action.payload,
      };
    },
    setSelectedDevicesMissingGeoJson: (state, action) => {
      return {
        ...state,
        selectedDevicesMissingGeoJson: action.payload,
      };
    },
    setDevicesEquipmentData: (state, action) => {
      return {
        ...state,
        devicesEquipmentData: action.payload,
      };
    },
    setBoundsForDevices: (state, action) => {
      return {
        ...state,
        boundsForDevices: action.payload,
      };
    },
    setSelectedDateForDevices: (state, action) => {
      return {
        ...state,
        selectedDateForDevices: action.payload,
      };
    },
    setBoundingBoxForDevices: (state, action) => {
      return {
        ...state,
        boundingBoxForDevices: action.payload,
      };
    },
    setIsIdleLayers: (state, action) => {
      return {
        ...state,
        isIdleLayers: action.payload,
      };
    },
    setIsTransitLayers: (state, action) => {
      return {
        ...state,
        isTransitLayers: action.payload,
      };
    },
    setIsMissingLayers: (state, action) => {
      return {
        ...state,
        isMissingLayers: action.payload,
      };
    },
    resetAllWeekDaysCombinedGeoJson: (state) => {
      state.allWeekDaysCombinedGeoJson = {
        type: "FeatureCollection",
        features: [],
      };
    },
  },
});

export const {
  reset,
  setSelectedLocationValue,
  setDevicesDashboardLocationValue,
  setDevicesSummaryRef,
  setDevicesMapRef,
  setIsBaseLayersLoading,
  setIsDevicesBaseLayersLoading,
  setIsDevicesRouteDataLoading,
  setBaseLayersGeojson,
  setDeviceBaseLayersGeojson,
  setIsBeaconBasedColor,
  setIsSummary,
  setSummaryData,
  setSelectedDay,
  setSelectedServiceForDetails,
  setCoveredLayersGeojson,
  setCoveredLayersDateWiseGeojson,
  setWeekCalenderValue,
  setSelectedProperty,
  setWeekSummaryFiles,
  setSelectedGISService,
  setSelectedGISServicePathOfMotion,
  setSelectedDevices,
  setSelectedGISServiceDate,
  setSelectedWeekSummary,
  setSelectedWeek,
  setDevicesColor,
  setPathOfMotionData,
  setDetailedData,
  setBeaconsListData,
  setBeaconTypeColors,
  setIsPathOfMotion,
  setUploadedKmlData,
  setPathOfMotionEntryPointsData,
  setDevicesUniqueColors,
  setAllWeekDaysCombinedGeoJson,
  setDevicesBeconCheckboxes,
  updateAllDevicesCheckboxes,
  updateDevicesBeconCheckboxes,
  setSelectedDevicesDashboard,
  setSelectedDevicesGeoJson,
  setSelectedDevicesIdleGeoJson,
  setSelectedDevicesTransitGeoJson,
  setSelectedDevicesMissingGeoJson,
  setDevicesEquipmentData,
  setBoundsForDevices,
  setBoundingBoxForDevices,
  setSelectedDateForDevices,
  setIsIdleLayers,
  setIsTransitLayers,
  setIsMissingLayers,
  resetAllWeekDaysCombinedGeoJson,
} = GISDashboardSlice.actions;

export default GISDashboardSlice.reducer;
