import React, { useEffect, useState } from "react";
import BannerTop from "./components/BannerTop";
import { Box, Typography } from "@mui/material";
import "./IntelDashboard.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useGetIntelCalendarDataMutation } from "../../services/Properties";
import { convertSecondsToReadable, truncateString } from "../../utils/common";
import CalendarEvent from "./components/CalendarEvent";

const IntelDashboard = () => {
  const [calendarData, setCalendarData] = useState([]);
  const [visibleRange, setVisibleRange] = useState({ from: null, to: null });
  const [skeletonEvents, setSkeletonEvents] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [getIntelCalendarData, { isLoading }] =
    useGetIntelCalendarDataMutation();

  // Fetch calendar data dynamically
  const fetchCalendarData = async (from, to) => {
    const response = await getIntelCalendarData({
      from,
      to,
      organisation: user?.organization,
      frequency: "daily",
    });
    if (response?.data?.results) {
      const calendarData = response?.data?.results.map((data) => {
        return {
          title: data?.group_name || "N/A",
          date: data?.start_date,
          extendedProps: {
            duration: data?.duration,
            type: data?.services,
            task: data?.device_count,
            group_key: data?.group_key,
          },
        };
      });
      setCalendarData(calendarData);
    }
  };

  const handleDatesSet = (dateInfo) => {
    // Extract start and end dates for the visible range
    const start = new Date(dateInfo.start).toISOString().split("T")[0];
    const end = new Date(dateInfo.end).toISOString().split("T")[0];

    // Fetch new data only if the range has not been fetched yet
    if (
      !visibleRange.from ||
      !visibleRange.to ||
      start !== visibleRange.from ||
      end !== visibleRange.to
    ) {
      setVisibleRange({ from: start, to: end });
      generateSkeletonEvents(dateInfo.start, dateInfo.end); // Create skeletons
      fetchCalendarData(start, end);
    }
  };

  const handleEventClick = (info) => {
    const location = info?.event?._def?.extendedProps?.group_key;
    const eventDate = new Date(info.event.start);
    const formattedDate = new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(eventDate);
    if (location && formattedDate) {
      const baseUrl = window.location.origin;
      const newUrl = `${baseUrl}/devices-dashboard?location=${location}&date=${formattedDate}`;
      window.open(newUrl, "_blank");
    }
  };

  const generateSkeletonEvents = (startDate, endDate) => {
    const skeletons = [];
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
      skeletons.push({
        title: "",
        date: currentDate.toISOString().split("T")[0],
        extendedProps: { isSkeleton: true },
      });
      currentDate.setDate(currentDate.getDate() + 1); // Increment day
    }

    setSkeletonEvents(skeletons);
  };

  return (
    <Box className="calendarWrapper">
      <div className="pageTitle"> Property Calendar </div>
      <Box className="calendarContainer">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridWeek"
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridWeek,dayGridMonth",
          }}
          height={700}
          eventClick={(info) => handleEventClick(info)}
          events={isLoading ? skeletonEvents : calendarData}
          eventContent={(eventInfo) => <CalendarEvent eventInfo={eventInfo} />}
          datesSet={(dateInfo) => handleDatesSet(dateInfo)}
          firstDay={0}
          views={{
            dayGridWeek: {
              firstDay: 0,
              dayHeaderFormat: { weekday: "short" },
              dayHeaderClassNames: "custom-day-header",
            },
            dayGridMonth: {
              dayMaxEvents: 1,
              moreLinkClick: "popover",
              title: "Month",
            },
          }}
          buttonText={{
            month: "Month",
            week: "Week",
          }}
          dayHeaderContent={(args) => (
            <div>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "1em", color: "#333333" }}
              >
                {args.text}
              </Typography>
            </div>
          )}
          dayCellContent={(cellInfo) => {
            const date = new Date(cellInfo.date);
            const formattedDate = date.toLocaleDateString("en-US", {
              day: "numeric",
            });
            return (
              <div>
                <div style={{ textAlign: "center" }}>
                  <Typography
                    fontWeight={"600"}
                    sx={{ margin: "5px 0", fontSize: "20px", color: "#878787" }}
                  >
                    {formattedDate}
                  </Typography>{" "}
                  {/* Date */}
                </div>
              </div>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default IntelDashboard;
