import { Box, Typography } from "@mui/material";
import React from "react";
import {
  capitalizeFirstLetter,
  convertSecondsToReadable,
  truncateString,
} from "../../../utils/common";

const CalendarEvent = ({ eventInfo }) => {
  const viewType = eventInfo.view.type; // Determine if the view is "dayGridWeek" or "dayGridMonth"

  const isSkeleton = eventInfo.event.extendedProps.isSkeleton;

  if (isSkeleton) {
    if (viewType === "dayGridWeek") {
      return (
        <Box sx={{ background: "white"}}>
          {[...Array(3)].map((_, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: "#f4f4f4",
                borderRadius: "8px",
                minHeight: "80px",
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                margin: "10px 0"
              }}
            >
              <Box
                sx={{
                  height: "10px",
                  backgroundColor: "#ebebeb",
                  borderRadius: "4px",
                  marginBottom: "8px",
                }}
              />
              <Box
                sx={{
                  height: "40px",
                  backgroundColor: "#ebebeb",
                  borderRadius: "4px",
                }}
              />
            </Box>
          ))}
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            backgroundColor: "#f4f4f4",
            borderRadius: "8px",
            padding: "10px",
            minHeight: "80px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "10px",
              backgroundColor: "#ebebeb",
              borderRadius: "4px",
              marginBottom: "8px",
            }}
          />
          <Box
            sx={{
              height: "40px",
              backgroundColor: "#ebebeb",
              borderRadius: "4px",
            }}
          />
        </Box>
      );
    }
  }
  return (
    <Box
      sx={{
        padding: "8px",
        boxShadow: "rgba(0, 0, 0, 0.02) 1px 0px 3px 1px",
        borderRadius: "8px",
      }}
    >
      <div>{eventInfo.timeText}</div>
      <Typography
        sx={{
          color: "#1d4117",
          fontSize: "12px",
          whiteSpace: "normal",
          overflow: "visible",
        }}
      >
        {capitalizeFirstLetter(eventInfo.event.title)}
      </Typography>
      {viewType === "dayGridWeek" && (
        <Box sx={{ marginTop: "5px", fontSize: "smaller", color: "#555" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <img height="16px" width="16px" src="/images/Timer.png" />
              <Typography
                sx={{
                  color: "#1d4117",
                  margin: "3px 0",
                  fontSize: "10px",
                  whiteSpace: "normal",
                }}
              >
                {`${convertSecondsToReadable(
                  eventInfo?.event?.extendedProps?.duration
                )}`}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <img height="16px" width="16px" src="/images/User.png" />
                <Typography
                  sx={{
                    color: "#1d4117",
                    margin: "3px 0",
                    fontSize: "10px",
                    whiteSpace: "normal",
                  }}
                >
                  {`${eventInfo?.event?.extendedProps?.task}`}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "5px",
              flexWrap: "nowrap",
              marginTop: "5px",
            }}
          >
            <img height="17px" width="17px" src="/images/Wrench.png" />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
                flexGrow: 1,
                marginTop: "3px",
              }}
            >
              {eventInfo?.event?.extendedProps?.type
                .split(",")
                .map((word, index, arr) => (
                  <Typography
                    key={index}
                    sx={{
                      color: "#1d4117",
                      margin: "3px 0",
                      fontSize: "10px",
                      whiteSpace: "nowrap", // Keep word and comma together
                      lineHeight: "0.2",
                    }}
                  >
                    {capitalizeFirstLetter(word.trim())}
                    {index < arr.length - 1 ? "," : ""}{" "}
                    {/* Add comma except for the last word */}
                  </Typography>
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CalendarEvent;
