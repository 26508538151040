import toGeoJSON from "@mapbox/togeojson";
import { Box, Dialog, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import "./ProductSelectionModal.css";
import { setProductSelected } from "../../slices/globalSlice";
import { IoClose } from "react-icons/io5";
import { getCustomColor } from "../../utils/theme";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaRoute } from "react-icons/fa";

const ProductSelectionModal = ({ isOpen, setIsOpen }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const SelectedProduct = localStorage.getItem("SelectedProduct");

  const handleSelect = (product) => {
    setIsOpen(false);
    if (product === "crew_planner") {
      navigate("/");
      dispatch(setProductSelected("crew_planner"));
      localStorage.setItem("SelectedProduct", "crew_planner");
    } else if (product === "crew_intel") {
      navigate("/intel-dashboard");
      dispatch(setProductSelected("crew_intel"));
      localStorage.setItem("SelectedProduct", "crew_intel");
    }
  };
  return (
    <Dialog open={isOpen} fullScreen maxWidth="lg">
      <div className="productSelectionWrapper">
        <div className="productSelectionHeader">
          <img src={"/images/logo.png"} width="150px" height="30px" />
          {SelectedProduct && (
            <IoClose
              size={30}
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            />
          )}
        </div>

        <Box className="productSelectionContianer">
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ color: blackShade1.main }}
          >
            What are you looking for ?
          </Typography>
          <Box className="productsContainer">
            <Box
              className="mainProduct"
              sx={{
                border:
                  SelectedProduct === "crew_planner" && "2px solid #aad48e",
              }}
              onClick={() => handleSelect("crew_planner")}
            >
              <FaMapLocationDot fontSize={30} color={blackShade1.main} />
              <Typography
                variant="body1"
                fontWeight={600}
                sx={{ fontSize: "18px", color: blackShade1.main }}
              >
                Crew Planner
              </Typography>
            </Box>
            <Box
              className="mainProduct"
              sx={{
                border: SelectedProduct === "crew_intel" && "2px solid #aad48e",
              }}
              onClick={() => handleSelect("crew_intel")}
            >
              <FaRoute fontSize={30} color={blackShade1.main} />
              <Typography
                variant="body1"
                fontWeight={600}
                sx={{ fontSize: "18px", color: blackShade1.main }}
              >
                Crew Intel
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
        <div className="patternLeft">
          <img src="/images/patternsLeft.png" />
        </div>
        <div className="patternRight">
          <img src="/images/patternsRight.png" />
        </div>
    </Dialog>
  );
};

export default ProductSelectionModal;
