import React, { useEffect, useState } from "react";
import {
  ControlPosition,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { calculateBounds, calculateZoom } from "../../utils/mapZoomCalculation";
import { setIsStreetView } from "../../slices/globalSlice";
import BaseServiceLayers from "../GISDashboard/Layers/BaseServiceLayers";
import CoveredServiceLayers from "../GISDashboard/Layers/CoveredServiceLayers";
import DevicesRouteLayer from "./Components/DevicesRouteLayer";
import { setBoundsForDevices } from "../../slices/gisDashboardSlice";
import BeaconBasedRouteLayer from "./Components/BeaconBasedRouteLayer";
import HoverDeviceDetails from "./Components/HoverDeviceDetails";
import { LayerColors } from "../../utils/defaultVars";

const DevicesDashboardMap = () => {
  const [devicesFilteredGeoJson, setDevicesFilteredGeoJson] = useState(null);
  const [devicesIdleFilteredGeoJson, setDevicesIdleFilteredGeoJson] =
    useState(null);
  const [devicesTransitFilteredGeoJson, setDevicesTransitFilteredGeoJson] =
    useState(null);
  const [devicesMisssingFilteredGeoJson, setDevicesMissingFilteredGeoJson] =
    useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const isBeaconBasedColor = useSelector(
    (state) => state.gisDashboard.isBeaconBasedColor
  );
  const boundingBoxForDevices = useSelector(
    (state) => state.gisDashboard.boundingBoxForDevices
  );
  const isIdleLayers = useSelector((state) => state.gisDashboard.isIdleLayers);
  const isTransitLayers = useSelector(
    (state) => state.gisDashboard.isTransitLayers
  );
  const isMissingLayers = useSelector(
    (state) => state.gisDashboard.isMissingLayers
  );

  const devicesUniqueColors = useSelector(
    (state) => state.gisDashboard.devicesUniqueColors
  );
  const selectedDevicesDashboard = useSelector(
    (state) => state.gisDashboard.selectedDevicesDashboard
  );
  const dispatch = useDispatch();
  const selectedDevicesGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesGeoJson
  );
  const selectedDevicesIdleGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesIdleGeoJson
  );
  const selectedDevicesTransitGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesTransitGeoJson
  );
  const selectedDevicesMissingGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesMissingGeoJson
  );
  const deviceBaseLayersGeojson = useSelector(
    (state) => state.gisDashboard.deviceBaseLayersGeojson
  );
  const beaconTypeColors = useSelector(
    (state) => state.gisDashboard.beaconTypeColors
  );
  const devicesBeconCheckboxes = useSelector(
    (state) => state.gisDashboard.devicesBeconCheckboxes
  );

  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  // Update the viewport when mapData changes
  useEffect(() => {
    if (
      !deviceBaseLayersGeojson ||
      !deviceBaseLayersGeojson.features ||
      deviceBaseLayersGeojson.features.length === 0
    ) {
      if (boundingBoxForDevices) {
        const zoom = calculateZoom(
          boundingBoxForDevices,
          viewport?.map?.width,
          viewport?.map?.height
        );
        setViewport((prevViewport) => ({
          ...prevViewport,
          center: {
            lat: (boundingBoxForDevices[1] + boundingBoxForDevices[3]) / 2,
            lng: (boundingBoxForDevices[0] + boundingBoxForDevices[2]) / 2,
          },

          zoom: zoom || 18,
        }));
      }
    } else {

      const bounds = calculateBounds(deviceBaseLayersGeojson.features);
      const zoom = calculateZoom(
        bounds,
        viewport?.map?.width,
        viewport?.map?.height
      );
      dispatch(setBoundsForDevices(bounds));
      setViewport((prevViewport) => ({
        ...prevViewport,
        center: {
          lat: (bounds[1] + bounds[3]) / 2,
          lng: (bounds[0] + bounds[2]) / 2,
        },
  
        zoom: zoom || 18,
      }));
    }

  }, [deviceBaseLayersGeojson]);

  useEffect(() => {
    if (mapsLibrary && map) {
      setViewport((prev) => ({
        ...prev,
        mapTypeControlOptions: {
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: 0,
        },
      }));
      const panorama = map?.getStreetView();
      panorama.addListener("visible_changed", () => {
        dispatch(setIsStreetView(panorama.getVisible()));
      });
    }
  }, [mapsLibrary, map]);

  useEffect(() => {
    if (selectedDevicesDashboard && selectedDevicesGeoJson) {
      const filteredFeatures = selectedDevicesGeoJson.features.filter(
        (feature) => {
          const deviceId = feature?.properties?.device_id;
          const beaconId = feature?.properties?.beacon_id;

          // Check if the device exists in the dashboard and its beacon is true
          return (
            selectedDevicesDashboard[deviceId] &&
            devicesBeconCheckboxes[deviceId][beaconId] === true
          );
        }
      );
      setDevicesFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [
    selectedDevicesDashboard,
    selectedDevicesGeoJson,
    devicesBeconCheckboxes,
  ]);

  useEffect(() => {
    if (selectedDevicesDashboard && selectedDevicesIdleGeoJson) {
      const filteredFeatures = selectedDevicesIdleGeoJson.features.filter(
        (feature) => {
          const deviceId = feature?.properties?.device_id;
          // const beaconId = feature?.properties?.beacon_id;

          // Check if the device exists in the dashboard and its beacon is true
          return selectedDevicesDashboard[deviceId] === true;
        }
      );
      setDevicesIdleFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [
    selectedDevicesDashboard,
    selectedDevicesIdleGeoJson,
    devicesBeconCheckboxes,
  ]);
  useEffect(() => {
    if (selectedDevicesDashboard && selectedDevicesTransitGeoJson) {
      const filteredFeatures = selectedDevicesTransitGeoJson.features.filter(
        (feature) => {
          const deviceId = feature?.properties?.device_id;
          //const beaconId = feature?.properties?.beacon_id;

          // Check if the device exists in the dashboard and its beacon is true
          return selectedDevicesDashboard[deviceId] === true;
        }
      );
      setDevicesTransitFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [
    selectedDevicesDashboard,
    selectedDevicesTransitGeoJson,
    devicesBeconCheckboxes,
  ]);
  useEffect(() => {
    if (selectedDevicesDashboard && selectedDevicesMissingGeoJson) {
      const filteredFeatures = selectedDevicesMissingGeoJson.features.filter(
        (feature) => {
          const deviceId = feature?.properties?.device_id;
          //const beaconId = feature?.properties?.beacon_id;

          // Check if the device exists in the dashboard and its beacon is true
          return selectedDevicesDashboard[deviceId] === true;
        }
      );
      setDevicesMissingFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [
    selectedDevicesDashboard,
    selectedDevicesMissingGeoJson,
    devicesBeconCheckboxes,
  ]);

  return (
    <div style={{ height: "92vh", width: "100%" }}>
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v)}
        gestureHandling={"greedy"}
        draggableCursor="default" // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        mapId={"84a4fccd359c6c70"}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        mapTypeControlOptions={{
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: ControlPosition.TOP_CENTER,
        }}
        stylers={true}
      >
        <BaseServiceLayers
          layerData={deviceBaseLayersGeojson}
          dimLayers={true}
        />
        <DevicesRouteLayer
          layerData={devicesFilteredGeoJson}
          colorsData={devicesUniqueColors}
          setHoverInfo={setHoverInfo}
          isLayerVisible={isBeaconBasedColor ? false : true}
        />
        <DevicesRouteLayer
          layerData={devicesIdleFilteredGeoJson}
          colorsData={devicesUniqueColors}
          setHoverInfo={setHoverInfo}
          isLayerVisible={!isBeaconBasedColor && isIdleLayers}
        />
        <DevicesRouteLayer
          layerData={devicesTransitFilteredGeoJson}
          colorsData={devicesUniqueColors}
          setHoverInfo={setHoverInfo}
          isLayerVisible={!isBeaconBasedColor && isTransitLayers}
        />
        <DevicesRouteLayer
          layerData={devicesMisssingFilteredGeoJson}
          colorsData={devicesUniqueColors}
          setHoverInfo={setHoverInfo}
          isLayerVisible={!isBeaconBasedColor && isMissingLayers}
        />

        <BeaconBasedRouteLayer
          layerData={devicesFilteredGeoJson}
          colorsData={beaconTypeColors}
          setHoverInfo={setHoverInfo}
          layerColor={null}
          isLayerVisible={isBeaconBasedColor}
        />
        <BeaconBasedRouteLayer
          layerData={devicesIdleFilteredGeoJson}
          colorsData={beaconTypeColors}
          setHoverInfo={setHoverInfo}
          layerColor={LayerColors?.idle?.rgb}
          isLayerVisible={isBeaconBasedColor && isIdleLayers}
        />
        <BeaconBasedRouteLayer
          layerData={devicesTransitFilteredGeoJson}
          colorsData={beaconTypeColors}
          setHoverInfo={setHoverInfo}
          layerColor={LayerColors?.transit?.rgb}
          isLayerVisible={isBeaconBasedColor && isTransitLayers}
        />
        <BeaconBasedRouteLayer
          layerData={devicesMisssingFilteredGeoJson}
          colorsData={beaconTypeColors}
          setHoverInfo={setHoverInfo}
          layerColor={LayerColors?.missing?.rgb}
          isLayerVisible={isBeaconBasedColor && isMissingLayers}
        />
        <HoverDeviceDetails hoverInfo={hoverInfo} />
      </Map>
    </div>
  );
};

export default DevicesDashboardMap;
