import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainScreen from '../screens/PlannerGoogleMap/MainScreen';
import Header from '../components/Header/Header';
import Login from '../screens/Login/Login';
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from '../utils/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from '../utils/constants';
import Survey from '../screens/Survey/Survey';
import Properties from '../screens/Properties/Properties';
import NotFound from '../screens/NotFound/NotFound';
import Register from '../screens/Register/Register';
import VerifyEmail from '../screens/VerifyEmail/VerifyEmail';
import NewUser from '../screens/NewUser/NewUser';
import ForgotPassword from '../screens/ForgotPassword/ForgotPassword';
import ResetPassword from '../screens/ResetPassword/ResetPassword';
import SummaryScreen from '../screens/SummaryScreen/SummaryScreen';
import GISDashboardApi from '../screens/GISDashboard/GISDashboardApi';
import UploadKmlScreen from '../screens/UploadKmlScreen/UploadKmlScreen';
import Beacons from '../screens/Beacons/Beacons';
import DevicesLiveLocation from '../screens/Devices/DevicesLiveLocation';
import DeviceDetails from '../screens/DeviceDetails/DeviceDetails';
import DevicesDashboard from '../screens/DevicesDashboard/DevicesDashboard';
import IntelDashboard from '../screens/IntelDashboard/IntelDashboard';

const AppRoutes = () => {
  
  return (
    <>
    <ToastContainer /> 
      <Header />
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route path={ROUTES.VERIFYEMAIL} element={<VerifyEmail />} />
        <Route path={ROUTES.RESETPASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESETPASSWORDLINK} element={<ResetPassword />} />
        <Route path={ROUTES.NEWUSER} element={<NewUser />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path={ROUTES.SURVEY} element={<Survey />} />
          <Route path={ROUTES.PARCEL} element={<Survey />} />
          <Route path={ROUTES.MAP} element={<MainScreen />} />
          <Route path={ROUTES.HOME} element={<Properties />} />
          <Route path={ROUTES.SUMMARY} element={<SummaryScreen />} />
          <Route path={ROUTES.DASHBOARD} element={<GISDashboardApi />} />
          <Route path={ROUTES.INTELDASHBOARD} element={<IntelDashboard />} />
          <Route path={ROUTES.DEVICESDASHBOARD} element={<DevicesDashboard />} />
          <Route path={ROUTES.UPLOADKML} element={<UploadKmlScreen/>} />
          <Route path={ROUTES.BEACON} element={<Beacons/>} />
          <Route path={ROUTES.DEVICES} element={<DevicesLiveLocation/>} />
          <Route path={ROUTES.DEVICEDETAILS} element={<DeviceDetails/>} />
          {/* <Route path={ROUTES.LANDINGPAGE} element={<LandingPage />} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
