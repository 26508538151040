import { createTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";

const { palette } = createTheme();

const theme = createTheme({
  palette: {
    secondary: {
      main: "#FF0000",
    },
    primary: {
      main: "#8FE445",
      dark: "#4EA403",
    },
    custom: {
      blackShade1: {
        main: "#3E4041",
        contrastText: "#fff",
        light: "#5F6163",
      },
      greenShade1: {
        main: "#E0FFC4",
      },
      greenAndBlue: {
        main: "#e3f7ed",
      },
    },
    error: {
      main: "#DF4C18",
    },
  },
  typography: {
    fontFamily: "Albert Sans",
    button: {
      textTransform: "none",
      fontSize: "16px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          overflowY: "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "8px",
            backgroundColor: "rgba(192, 192, 192, 0.2)",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "rgba(87, 87, 87, 0.2)",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
  },
});

const getCustomColor = (colorName) => {
  return theme.palette.custom[colorName];
};

export { theme, getCustomColor };
