import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  checkboxClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDevicesBeconCheckboxes,
  setSelectedDevicesDashboard,
  updateAllDevicesCheckboxes,
} from "../../../slices/gisDashboardSlice";
import { RiAddBoxFill, RiSubtractFill } from "react-icons/ri";
import { getCustomColor } from "../../../utils/theme";
import { StyledTableCell } from "../../GISDashboard/Layers/OperationTable";
import BeaconsGanttChart from "./BeaconsGanttChart";
import { formatTotalTime, parseTimeString } from "../../../utils/common";
import { FaChartBar } from "react-icons/fa";

const DevicesSummaryCard = ({ deviceName }) => {
  const dispatch = useDispatch();
  const greenAndBlue = getCustomColor("greenAndBlue");
  const blackShade1 = getCustomColor("blackShade1");

  const [showBreakdown, setShowBreakdown] = useState(true);
  const [devicesFilteredGeoJson, setDevicesFilteredGeoJson] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [formattedIdleResult, setformattedIdleResult] = useState([]);
  const [formattedTransitResult, setformattedTransitResult] = useState([]);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const isBeaconBasedColor = useSelector(
    (state) => state.gisDashboard.isBeaconBasedColor
  );
  const beaconTypeColors = useSelector(
    (state) => state.gisDashboard.beaconTypeColors
  );
  const selectedDevicesDashboard = useSelector(
    (state) => state.gisDashboard.selectedDevicesDashboard
  );
  const devicesUniqueColors = useSelector(
    (state) => state.gisDashboard.devicesUniqueColors
  );
  const devicesEquipmentData = useSelector(
    (state) => state.gisDashboard.devicesEquipmentData
  );

  const devicesBeconCheckboxes = useSelector(
    (state) => state.gisDashboard.devicesBeconCheckboxes
  );
  const selectedDevicesGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesGeoJson
  );
  const idleGeoJSONData = useSelector(
    (state) => state.gisDashboard.selectedDevicesIdleGeoJson
  );
  const transitGeoJSONData = useSelector(
    (state) => state.gisDashboard.selectedDevicesTransitGeoJson
  );
  const handleDevicesCheckboxChange = (key) => {
    dispatch(setSelectedDevicesDashboard(key));
  };

  const handleBreakdownClick = () => {
    setShowBreakdown((prev) => !prev);
  };

  const handleBeaconsCheckboxChange = (beaconId, deviceId) => {
    dispatch(updateDevicesBeconCheckboxes({ beaconId, deviceId }));
  };

  const handleAllBeaconsCheckboxChange = (deviceId, value) => {
    const updatedObj = {};
    for (let key in devicesBeconCheckboxes[deviceId]) {
      updatedObj[key] = value;
    }
    dispatch(updateAllDevicesCheckboxes({ deviceId, updatedObj }));
  };

  const processIdleTransitGeoJSONData = (geoJSONData) => {
    if (!geoJSONData) return {};

    function calculateTimeInSeconds(startTime, endTime) {
      const start = new Date(startTime);
      const end = new Date(endTime);
      const difference = end.getTime() - start.getTime();
      const differenceInSeconds = Math.round(difference / 1000);
      return differenceInSeconds;
    }

    // Function to convert the date to a readable format
    function formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
    }

    return geoJSONData.features.reduce((acc, item) => {
      const { device_id, start_time, end_time } = item.properties;
      const timeInSeconds = calculateTimeInSeconds(start_time, end_time);

      if (!acc[device_id]) {
        acc[device_id] = {
          total_time_in_seconds: 0,
          start_time: formatDate(start_time),
          end_time: formatDate(end_time),
        };
      }

      acc[device_id].total_time_in_seconds += timeInSeconds;
      acc[device_id].end_time = formatDate(end_time); // Update end time to the latest

      return acc;
    }, {});
  };

  useEffect(() => {
    // Process both idle and transit data using the common function
    const idleResult = processIdleTransitGeoJSONData(idleGeoJSONData);
    const transitResult = processIdleTransitGeoJSONData(transitGeoJSONData);

    function formatTotalTime(totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      let timeString = "";
      if (hours > 0) {
        timeString += `${hours}h `;
      }
      if (minutes > 0 || hours > 0) {
        timeString += `${minutes}m `;
      }
      timeString += `${seconds}s`;
      return timeString;
    }

    // Transform the data into a readable format for both idle and transit
    const formatResult = (result) =>
      Object.keys(result).map((deviceId) => {
        const device = result[deviceId];
        return {
          device_id: deviceId,
          total_time_seconds: device.total_time_in_seconds,
          total_time: formatTotalTime(device.total_time_in_seconds),
          start_time: device.start_time,
          end_time: device.end_time,
        };
      });

    setformattedIdleResult(formatResult(idleResult));
    setformattedTransitResult(formatResult(transitResult));
  }, [idleGeoJSONData, transitGeoJSONData]);

  useEffect(() => {
    if (selectedDevicesGeoJson) {
      const filteredFeatures = selectedDevicesGeoJson.features.filter(
        (feature) => {
          const deviceId = feature?.properties?.device_id;

          // Check if the device exists in the dashboard and its beacon is true
          return deviceId === deviceName;
        }
      );
      setDevicesFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [selectedDevicesGeoJson]);

  const equipmentData = devicesEquipmentData[deviceName];
  const beaconCheckboxes = devicesBeconCheckboxes[deviceName];

  const timefunctioncalculateTotalProductionTime = (equipmentSummary) => {
    let totalSeconds = 0;

    // Loop through each beacon type and add its time to the totalObject.keys(timeSummary).forEach(beaconType => {
    equipmentSummary?.forEach((equipment) => {
      const timeString = equipment?.total_time;
      totalSeconds += parseTimeString(timeString);
    });

    return formatTotalTime(totalSeconds);
  };

  const allEquipmentCheck = Object.values(beaconCheckboxes).every(
    (value) => value === true
  );
  return (
    <>
      <Box className="summaryCardBox">
        <Box className="summaryCardHeaderContainer">
          <div className="summaryCheckboxContainer">
            <Checkbox
              size="small"
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: devicesUniqueColors[deviceName],
                },
              }}
              checked={
                selectedDevicesDashboard[deviceName]
                  ? selectedDevicesDashboard[deviceName]
                  : false
              }
              onChange={() => handleDevicesCheckboxChange(deviceName)}
            />
            <Typography
              component="h3"
              fontWeight="600"
              sx={{
                color: devicesUniqueColors[deviceName],
              }}
              variant="body1"
            >
              {deviceName}
            </Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                background: devicesUniqueColors[deviceName],
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            ></Box>
          </div>
        </Box>
        <Divider />

        {/* Conditionally render the breakdown table */}
        {equipmentData && (
          <Collapse in={showBreakdown} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "10px" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ flex: 1, fontSize: 15, marginTop: 5 }}>
                  <Typography
                    component="div"
                    fontWeight="600"
                    fontSize="14px"
                    variant="subtitle2"
                  >
                    Production Data
                  </Typography>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleOpenDialog}
                  >
                    <FaChartBar color="white" size={22} />
                  </Button>
                </div>
              </div>

              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Checkbox
                            size="small"
                            checked={allEquipmentCheck}
                            sx={{
                              [`&, &.${checkboxClasses.checked}`]: {
                                padding: 0,
                                color: "white",
                              },
                            }}
                            disabled={!selectedDevicesDashboard[deviceName]}
                            onChange={() =>
                              handleAllBeaconsCheckboxChange(
                                deviceName,
                                !allEquipmentCheck
                              )
                            }
                          />
                          Equipment Name
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Total Time
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equipmentData?.map((equipment) => {
                      return (
                        <TableRow key={equipment?.beacon_name}>
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Checkbox
                                size="small"
                                checked={
                                  beaconCheckboxes[equipment?.beacon_id]
                                    ? beaconCheckboxes[equipment?.beacon_id]
                                    : false
                                }
                                sx={{
                                  [`&, &.${checkboxClasses.checked}`]: {
                                    padding: 0,
                                  },
                                }}
                                disabled={!selectedDevicesDashboard[deviceName]}
                                onChange={() =>
                                  handleBeaconsCheckboxChange(
                                    equipment?.beacon_id,
                                    deviceName
                                  )
                                }
                              />
                              {isBeaconBasedColor && (
                                <Box
                                  className={`Plannercolor`}
                                  sx={{
                                    backgroundColor:
                                      beaconTypeColors[equipment?.beacon_type],
                                    cursor: "pointer",
                                  }}
                                />
                              )}

                              {equipment?.beacon_name}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {equipment?.total_time}
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  padding: "20px 20px 0 20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ color: blackShade1.main }}
                  variant="body2"
                  fontWeight="600"
                  component="span"
                >
                  Total Production Time :{" "}
                </Typography>
                <Typography variant="body2" component="span">
                  {equipmentData &&
                    timefunctioncalculateTotalProductionTime(equipmentData)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  padding: "5px 20px",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ color: blackShade1.main }}
                  variant="body2"
                  fontWeight="600"
                  component="span"
                >
                  Idle / Less confidence production
                </Typography>
                {formattedIdleResult &&
                formattedIdleResult.find(
                  (device) => device.device_id === deviceName
                ) ? (
                  <Typography variant="body2">
                    {
                      formattedIdleResult.find(
                        (device) => device.device_id === deviceName
                      ).total_time
                    }
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No idle data available
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ margin: "10px" }} className="devicesTimelineContainer">
              <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>Gantt Chart - {deviceName}</DialogTitle>
                <DialogContent>
                  <BeaconsGanttChart
                    equipmentData={equipmentData}
                    devicesData={devicesFilteredGeoJson}
                    deviceId={deviceName}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Collapse>
        )}

        <div className="summaryCardFooter">
          <div>
            <Box
              className="breakDownButton"
              onClick={handleBreakdownClick}
              sx={{ background: greenAndBlue.main }}
            >
              {showBreakdown ? (
                <RiSubtractFill color="#326703" fontSize={16} />
              ) : (
                <RiAddBoxFill color="#326703" fontSize={16} />
              )}
              <Typography
                component="div"
                fontWeight="600"
                fontSize="12px"
                sx={{ color: "primary.dark" }}
                variant="subtitle2"
              >
                Breakdown by Equipments
              </Typography>
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default DevicesSummaryCard;
